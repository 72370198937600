<template>
  <section
    id="gourmet"
  >
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-row
            align="center"
            justify="center"
          >
            <v-card
              v-for="(product, i) in productos"
              :key="i"
              class="mx-auto mt-5"
              width="580"
              elevation="24"
            >
              <v-carousel
                hide-delimiter-background
                delimiter-icon="mdi-minus"
                height="630"
              >
                <v-carousel-item
                  v-for="(imagen, n) in product.imagenes"
                  :key="n"
                  container
                  :src="imagen.src"
                  :lazy-src="imagen.src"
                />
              </v-carousel>
              <v-card-title>
                {{ product.nombre }}
              </v-card-title>
              <v-card-actions>
                <v-spacer />
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-3 d-none d-lg-block d-print-block"
                      icon
                      v-bind="attrs"
                      @click="product.dialog = true"
                      v-on="on"
                    >
                      <v-btn
                        color="orange"
                        fab
                        small
                      >
                        <v-icon
                          color="white"
                        >
                          mdi-fullscreen
                        </v-icon>
                      </v-btn>
                    </v-btn>
                  </template>
                  <span>Pantalla completa</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      @click="product.mostrar = !product.mostrar"
                      v-on="on"
                    >
                      <v-btn
                        color="primary"
                        fab
                        small
                      >
                        <v-icon
                          color="white"
                        >
                          {{ product.mostrar ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                        </v-icon>
                      </v-btn>
                    </v-btn>
                  </template>
                  <span>Descripción</span>
                </v-tooltip>
              </v-card-actions>
              <v-expand-transition>
                <div v-show="product.mostrar">
                  <v-divider />
                  <v-card-text>
                    {{ product.des }}
                    <span v-html="product.disp" />
                  </v-card-text>
                </div>
              </v-expand-transition>
              <v-dialog
                :key="product.disp"
                v-model="product.dialog"
                fullscreen
                transition="fade-transition"
              >
                <v-card
                  color="grey darken-4"
                  width="1080"
                  dark
                >
                  <v-app-bar
                    color="primary"
                    fixed
                    flat
                  >
                    <h2>{{ product.nombre }}</h2>
                    <v-spacer />
                    <v-btn
                      class="mx-1 mx-md-3"
                      icon
                      small
                      @click="product.dialog = !product.dialog"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-app-bar>
                  <v-responsive min-height="100vh">
                    <v-container
                      class="pa-0 fill-height"
                      fluid
                    >
                      <v-carousel
                        hide-delimiters
                        height="950"
                      >
                        <v-carousel-item
                          v-for="(pic, j) in product.imagenes"
                          :key="j"
                        >
                          <v-img
                            contain
                            :aspect-ratio="16/9"
                            :src="pic.src"
                            :lazy-src="pic.src"
                            :alt="pic.alt"
                          />
                        </v-carousel-item>
                      </v-carousel>
                    </v-container>
                  </v-responsive>
                </v-card>
              </v-dialog>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
  import dc1000 from '@/assets/dc1000.jpg'
  import dcCorte from '@/assets/dcCorte.jpg'
  import db1000 from '@/assets/db1000.jpg'
  import dbCorte from '@/assets/dbCorte.jpg'
  import dbCorte2 from '@/assets/dbCorte2.jpg'
  import p1000 from '@/assets/p1000.jpg'
  import pCorte from '@/assets/pCorte.jpg'
  import pCorte2 from '@/assets/pCorte2.jpg'
  export default {
    name: 'Gourmet',
    metaInfo: { title: 'Gourmet' },
    data: () => ({
      show: false,
      productos: [
        {
          nombre: 'Delicias de cabracho con langostinos y pimientos del piquillo',
          mostrar: false,
          dialog: false,
          des: 'Producto derivado del pudin de Cabracho, al que se le ha añadido un guiso hecho conlangostinos troceados, ajo, perejil y pimientos del piquillo. Por su textura y composición, podríamos servirlo como plato principal.',
          disp: '<br>Diponibilidad<br><ul><li>1000g: Caja de 1 unidad</li></ul>Conservación: entre 2ºC y 8ºC<br>Caducidad: 6 meses',
          imagenes: [
            {
              src: dc1000,
              alt: 'Delicias de cabracho con langostinos y pimientos del piquillo cantdelimar tarrina',
            },
            {
              src: dcCorte,
              alt: 'Presentación exquisita de Delicias de cabracho con langostinos y pimientos del piquillo',
            },
          ],
        },
        {
          nombre: 'Delicias de bacalo',
          mostrar: false,
          dialog: false,
          des: 'Producto pensado para la hostelería, con infinidad de aplicaciones por su textura y sabor. La mejor forma de trabajarlo, sería introduciendo el producto en una manga pastelera y proceder a su utilización. Aplicaciones: Exquisito sobre una rebanada de pan tostado y gratinado. Para rellenos: de pimientos, volavanes, para empanadillas, croquetas, cannelones, buñuelos, etc',
          disp: '<br>Diponibilidad<br><ul><li>1000g: Caja de 1 unidad</li></ul>Conservación: entre 2ºC y 8ºC<br>Caducidad: 6 meses',
          imagenes: [
            {
              src: dbCorte,
              alt: 'Presentación de diferentes productos para aplicar las delicias de bacalao',
            },
            {
              src: db1000,
              alt: 'Tarrina grande delicias de bacalao cantdelimar',
            },
            {
              src: dbCorte2,
              alt: 'Presentación gourmet delicias de bacalao exquisita',
            },
          ],
        },
        {
          nombre: 'Pastel de pulpo',
          mostrar: false,
          dialog: false,
          des: '"Su principal característica es que en su elaboración se emplea un 60 % de pulpo. Es importante servirlo caliente para apreciar todo su sabor. Como ejemplo de presentación sería: Loncha del pastel de Pulpo de 1 cm de grosor, calentado en horno o microondas durante 30 segundos, colocado sobre rebanada de pan tostado y espolvoreado con pimentón y chorrito de aceite de oliva.',
          disp: '<br>Diponibilidad<br><ul><li>1000g: Caja de 1 unidad</li></ul>Conservación: entre 2ºC y 8ºC<br>Caducidad: 6 meses',
          imagenes: [
            {
              src: p1000,
              alt: 'Presentación principal con patela de pulpo. En palto y tarrina.',
            },
            {
              src: pCorte,
              alt: 'Presentación de pastel de pulpo por cantdelimar.',
            },
            {
              src: pCorte2,
              alt: 'Plato típico de pastel de pulpo presentado por cantdelimar.',
            },
          ],
        },
      ],
    }),
  }
</script>
